export default function Blogitem() {
  return (
    <div>
      <link rel="stylesheet" href="./template/css/page__blog.css" />
      {/* <div
        className="tt-breadcrumb"
        style={{
          backgroundImage: 'url("./template/images/breadcrumb_bg.jpg")',
        }}
      >
        <div className="container container-lg-fluid">
          <ul>
            <li>
              <a href="../">Home</a>
            </li>
            <li>Blog</li>
            <li><b>Enhancing Safety and Efficiency:
Explore Our Electrical and Security Services</b></li>
          </ul>
        </div>
      </div> */}
      <main id="tt-pageContent">
        <div className="section-indent">
          <div className="container container-md-fluid">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12">
                <div className="blog-single">
                  <div className="blog-single__wrapper">
                    <div className="blog-single__data">
                      <div className="tt-col">
                        <div className="data__time">6 May, 2020</div>
                      </div>
                      <div className="tt-col">
                        <div className="data__posted">
                          Posted by: <a href="#">Admin</a>
                        </div>
                      </div>
                    </div>
                    <h1 className="blog-single__title">
                      Enhancing Safety and Efficiency: Explore Our Electrical
                      and Security Services
                    </h1>
                    <div className="blog-single__img">
                      <img src="./template/images/Projects/proj5.jpg" alt />
                    </div>
                    <h2 className="blog-single__subtitle blog-single__subtitle-top">
                      Home Electrical Repair
                    </h2>
                    <p>
                      In today's rapidly evolving world, the importance of
                      reliable electrical and security systems cannot be
                      overstated. At Popular Electrical & Security Services, we
                      take pride in offering comprehensive solutions that
                      prioritize safety, efficiency, and peace of mind for our
                      clients. With over 15 years of industry experience, we are
                      committed to delivering excellence in every project we
                      undertake.
                    </p>
                    <br />

                    <h1>Our Expertise in Electrical Services</h1>

                    <p>
                      From residential homes to large industrial complexes, our
                      team of skilled electricians specializes in a range of
                      electrical services designed to meet diverse needs:
                    </p>
                    <div className="row tt-indent-top">
                      <div className=" ">
                        <ul className="tt-list01">
                          <li>
                            <b>Commercial and Residential Wiring: </b>Whether
                            you're building a new property or renovating an
                            existing one, our experts ensure safe and efficient
                            electrical wiring and fitting tailored to your
                            specifications.
                          </li>
                          <li>
                            <b>Pole Erection and Cable Laying: </b>We excel in
                            the installation and maintenance of utility poles
                            and the laying of cables, ensuring reliable
                            electrical distribution and connectivity.
                          </li>
                          <li>
                            <b>Meter Installations: </b>Our professionals handle
                            single-phase and three-phase meter works with
                            precision, ensuring accurate measurement and billing
                            for electrical consumption
                          </li>
                          <br />
                        </ul>
                      </div>
                    </div>

                    <h1>Empowering Security with Cutting-Edge Solutions:</h1>

                    <p>
                      At Popular Electrical & Security Services, we understand
                      the importance of protecting what matters most. Our
                      security services are designed to safeguard residential
                      and commercial properties:
                    </p>
                    <div className="row tt-indent-top">
                      <div className=" ">
                        <ul className="tt-list01">
                          <li>
                            <b>CCTV Camera Installation: </b>Benefit from our
                            expertise in installing and configuring CCTV cameras
                            for effective surveillance and monitoring.
                          </li>
                          <li>
                            <b>Security Alarm Systems: </b>We offer
                            comprehensive security alarm systems that detect
                            intrusion and provide peace of mind for homeowners
                            and businesses alike.
                          </li>
                          <li>
                            <b>Home and Business Automation: </b>Explore the
                            convenience and security of automation with our
                            solutions, including smart lighting, climate
                            control, and access management.
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="tt-col-img__top">
                      <div className="row tt-col-img">
                        <div className="col-sm-6">
                          <img src="./template/images/Projects/proj6.jpg" alt />
                        </div>
                        <div className="col-sm-6">
                          <img src="./template/images/Projects/proj7.jpg" alt />
                        </div>
                      </div>
                    </div>
                    <h3 className="blog-single__subtitle blog-single__subtitle-top">
                      Contact Us Today:
                    </h3>
                    <p>
                      Whether you're planning a new construction project, need
                      maintenance services, or want to enhance security at your
                      property, Popular Electrical & Security Services is here
                      to help. Contact us today to discuss your requirements and
                      discover how we can partner with you to achieve your
                      electrical and security goals. <br /> Experience the
                      difference with Popular Electrical & Security Services –
                      your trusted partner for superior electrical and security
                      solutions.
                    </p>
                    <div className="blog-single__meta">
                      <div className="tt-col">
                        <a href="tel:+917972144809" style={{ color: "black" }}>
                          <span className="icon-telephone"></span>
                          <b>+91 7972 144 809</b>
                        </a>
                      </div>
                      <div className="tt-col">
                        <ul className="tt-social">
                          <li>
                            <a
                              href="https://x.com/Popular4u_in?t=UcIT14BKpBOwGpe8nJjxVA&s=09"
                              className="icon-twitter-logo-button"
                            />
                          </li>
                          <li>
                            <a
                              href="https://www.facebook.com/share/dEUURYQZzdhZs1aL/?mibextid=qi2Omg"
                              className="icon-facebook-logo-button"
                            />
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/popular4u.in?utm_source=qr&igsh=MzNlNGNkZWQ4Mg=="
                              className="icon-instagram-logo"
                            />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="divider d-block d-sm-none" />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
